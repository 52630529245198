<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-row>
      <div style="background-color: white">
        <el-button
          type="primary"
          style="
            padding: 1rem 2.5rem;
            margin-bottom: 1rem;
          "
          @click="openDialog()"
          >添加体育宝子类目</el-button
        >
        <el-table
          :data="typeList"
          :header-cell-class-name="'table-header'"
          v-loading="is_loading"
          row-key="id"
          :tree-props="{ hasChildren: 'hasChildren', children: 'childList' }"
        >
          >

          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="名称" prop="name"> </el-table-column>s
          <el-table-column label="图片" prop="">
            <template slot-scope="scope">
              <el-image :src="scope.row.pic" style="width:3rem"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="weights"> </el-table-column>
          <el-table-column
            label="添加时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column
            label="更新时间"
            prop="update_time"
          ></el-table-column>

          <el-table-column label="操作" prop="" width="180px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="small"
                @click="if_delete(scope.row)"
                >删除</el-button
              >
              <el-button type="warning" size="small" @click="edit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="typeTotal"
          :size="10"
          :current-page="currentPage"
          @current-change="changeCurrentPage"
        ></el-pagination>
        <!-- 确认删除 -->
        <el-dialog
          title="提示"
          :visible.sync="confirmDelete"
          width="20%"
          :before-close="handleClose"
          :append-to-body="true"
        >
          <div style="width: 100%; text-align: center">确认删除?</div>
          <span
            slot="footer"
            class=""
            style="display: flex; justify-content: space-around"
          >
            <el-button type="danger" @click="deleteType()">确 定</el-button>
            <el-button type="primary" @click="handleClose">取 消</el-button>
          </span>
        </el-dialog>
        <!-- 新增 -->
        <el-dialog
          title="添加类目"
          :visible.sync="addVisible"
          :width="ks?'90%':'35%'"
          :before-close="handleClose"
        >
          <div class="dialog-body">
            <el-form :model="form" id="new-good-form" :rules="rules">
              <el-form-item label-width="8rem" label="分类名称" prop="name"
                ><el-input v-model="form.name"></el-input
              ></el-form-item>
              <el-form-item
                label-width="8rem"
                label="排序"
                prop="categories_child"
                ><el-input v-model="form.weights"></el-input
              ></el-form-item>
              <el-form-item label-width="8rem" label="图片"
                ><avatar-uploader
                  @getImgUrl="getCover"
                  :clear="if_clear"
                ></avatar-uploader
              ></el-form-item>
              <el-form-item label-width="8rem" label="类目级别">
                <el-radio v-model="form.type" :label="1">一级类目</el-radio>
                <el-radio v-model="form.type" :label="2">二级类目</el-radio>
              </el-form-item>
              <el-form-item
                label-width="8rem"
                label="父类目"
                v-if="form.type == 2"
              >
                <el-select
                  remote
                  :remote-method="getCategoriesChild"
                  v-model="form.parent_id"
                  @focus.once="getCategoriesChild()"
                >
                  <el-option
                    v-for="item in addTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="addNewType()">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 修改类目 -->
        <el-dialog
          title="修改类目"
          :visible.sync="updateVisible"
          :width="ks?'95%':'35%'"
          :before-close="handleClose"
        >
          <div class="dialog-body">
            <el-form :model="form" id="new-good-form" :rules="rules">
              <el-form-item label-width="8rem" label="类目名称" prop="name">
                <el-input
                  v-model="form.name"
                  :placeholder="temp.name"
                ></el-input>
              </el-form-item>
              <el-form-item label-width="8rem" label="类目图片" prop="name">
                <avatar-uploader
                  @getImgUrl="getCover"
                  :url="temp.pic"
                ></avatar-uploader>
              </el-form-item>
               <el-form-item label-width="8rem" label="类目级别">
                <el-radio v-model="form.type" :label="1">一级类目</el-radio>
                <el-radio v-model="form.type" :label="2">二级类目</el-radio>
              </el-form-item>
              <el-form-item
                label-width="8rem"
                label="父类目"
                v-if="form.type == 2"
              >
                <el-select
                  remote
                  :remote-method="getCategoriesChild"
                  v-model="form.parent_id"
                  @focus.once="getCategoriesChild()"
                >
                  <el-option
                    v-for="item in addTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label-width="8rem"
                label="排序"
                prop="categories_child"
              >
                <el-input
                  v-model="form.weights"
                  :placeholder="temp.weights"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 绑定子类目 -->
        <el-dialog
          title="绑定科目"
          :visible.sync="bindVisible"
          width="35%"
          :before-close="handleClose"
        >
          <div class="dialog-body">
            <el-transfer
              filterable
              filter-placeholder="请选择绑定的科目"
              v-model="haveCate"
              :data="categories_list"
              :props="{
                key: 'name',
                label: 'name'
              }"
              :titles="['所有类目', '拥有类目']"
            >
            </el-transfer>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="bind()">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </el-row>
  </div>
</template>

<script>
import avatarUploader from "../../components/avatarUploader.vue";
export default {
  components: {
    avatarUploader
  },
  data() {
    return {
      nav: { firstNav: "体育宝管理中心", secondNav: "类目管理" },
      temp: {},
      is_loading: false,
      if_clear: false,
      addVisible: false,
      updateVisible: false,
      bindVisible: false,
      deleteId: null,
      confirmDelete: false,
      currentPage: 1,
      typeTotal: 0,
      typeList: [],
      categories_list: [],
      form: {},
      rules: {},
      haveCate: [],
      ks:'',
    };
  },
  updated(){
     this.ks=window.screen.width<768
  },
  computed: {},
  methods: {
    getCover(v) {
      this.form.url = v;
    },
    changeCurrentPage(v) {
      this.currentPage = v;
    },
    getCategoriesChild() {
      this.search_loading = true;
      this.editableId = null;
      let url = "/user/mechanismCategory/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            // mechanism_id: this.$id,
            type: 1,
            source: "商城"
          }
        })
        .then(res => {
          this.addTypeList = res.data.data.rows;
          this.search_loading = false;
        });
    },
    getTypeList() {
      this.is_loading = true;
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 1,
            source: "商城"
          }
        })
        .then(res => {
          this.typeList = res.data.data;

          // this.typeTotal = res.data.data.total;
          this.is_loading = false;
        });
    },

    if_delete(row) {
      this.temp = row;
      this.confirmDelete = true;
    },
    deleteType() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.temp.id,
          status: 1
        })
        .then(res => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "删除成功!" });
            this.confirmDelete = false;
            this.getTypeList();
          }
        });
    },
    update() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.temp.id,
          name: this.form.name ? this.form.name : null,
          pic: this.form.url ? this.form.url : null,
        })
        .then(res => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "更新成功!" });
            this.temp = null;
            this.updateVisible = false;
            this.getTypeList();
          }
        });
    },
    edit(row) {
      this.updateVisible = true;
      this.temp = row;
      console.log(this.temp);
    },

    getAvatarUrl(v) {
      this.form.pic = v;
    },
    handleClose(done) {
      this.if_clear = false;
      // this.$confirm("确认关闭？")
      //   .then(_ => {
          this.addVisible = false;
          this.updateVisible = false;
          this.confirmDelete = false;
          this.bindVisible = false;
          this.form = {};
          this.temp = {}
          this.if_clear = true;
          done();
        // })
        // .catch(_ => {});
    },

    openDialog() {
      // this.form = {};
      this.addVisible = true;
    },
    addNewType() {
      this.if_clear = false;

      let url = "/user/mechanismCategory/insert";
      this.$axios
        .post(url, {
          name: this.form.name ? this.form.name : null,
          parent_id: this.form.parent_id ? this.form.parent_id : null,
          source: "商城",
          pic: this.form.url,
          type: this.form.type,
          status: 2
        })
        .then(res => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "添加成功!" });
            this.form = {};
            this.addVisible = false;
            this.if_clear = true;
            this.getTypeList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 1
          }
        })
        .then(res => {
          this.categories_list = res.data.data.rows;
        });
    }
  },
  mounted() {
    this.getTypeList();
    this.getCategoriesList();
  }
};
</script>

<style></style>
